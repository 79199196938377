<template>
  <div class="template-main">

    <div class="template-main-breadcrumb">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <UserDetail :isUser="true"></UserDetail>
  </div>
</template>

<script>
import UserDetail from './components/userDetail'
export default {
  name: "user-detail",
  data(){
    return{

    }
  },
  components:{
    UserDetail
  },
}
</script>

<style scoped lang="scss">
// .template-main{
//   //margin: 20px;
//   padding:20px;
//   background: #FFFFFF;
//   box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
//   border-radius: 4px;

//   .margin-bottom-10{
//     margin-bottom: 20px;
//   }
//   .top{
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
// }
.template-main-breadcrumb{
  margin: 20px 0 0 20px;
}
</style>
