<template>
<div class="user-detail">
  <div class="layout-content">
    <Row>
      <i-col span="2">
        <div class="head-img">
          <img v-if="userDetail.avatar" :src="userDetail.avatar">
          <p v-else-if="userDetail.nickname">{{userDetail.nickname && userDetail.nickname[0].toUpperCase()}}</p>
        </div>
      </i-col>
      <i-col span="22">
        <Row>
          <i-col span="24" class="project-detail-info">
            <div class="info-item" >
              <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;">
                {{ userDetail.nickname }}
              </strong>
              <!--                <Button type="primary" @click="showEditSite">编辑</Button>-->
            </div>
          </i-col>
        </Row>
        <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
          <i-col span="8" v-if="userDetail.nickname">昵称 : {{userDetail.nickname}}(ID:{{userDetail.id}})</i-col>
          <i-col span="8" v-if="userDetail.realname">姓名 : {{userDetail.realname}}</i-col>
          <i-col span="8">手机号 : {{ userDetail.mobile }}</i-col>
         <!-- <i-col span="8" v-if="userDetail.realname">角色 : {{userDetail.realname}}</i-col> -->
        </Row>
        <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
          <i-col span="8">所在组织: {{userDetail.site && userDetail.site.name}}(ID:{{userDetail.site && userDetail.site.id}})</i-col>
          <i-col span="8">所属院系/部门 : {{userDetail.siteUser && userDetail.siteUser.department}}</i-col>
        </Row>
        <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
          <i-col span="8" v-if="userDetail.created_at">账号创建时间 : {{ userDetail.created_at }}</i-col>
          <i-col span="8" v-if="userDetail.userStat">最后登录时间 : {{ userDetail.userStat && showDate(userDetail.userStat.login_last_time)}}</i-col>
          <i-col span="8" v-if="userDetail.userStat">最后登录IP : {{ userDetail.userStat && userDetail.userStat.login_last_ip}}</i-col>
        </Row>
      </i-col>
    </Row>
  </div>
  <div class="tab-cont">
    <div class="tab-top">
      <div class="tab-top-l">
        <div :class="currentIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)">{{item.name}}</div>
      </div>
    </div>
    <component :is="tabList[currentIndex].componentName" :userId="userId" :userDetail="userDetail" :score="score" :isInfo="true" :isSuper="true" @editSuccess="getDetail"> </component>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import RecordList from './detail/recordList'
import Integral from './detail/integral'
import StuStatistics from '@/components/stuStatistics'
import TeacherAuth from './teacherAuth'
export default {
  name: "userDetail",
  data(){
    return {
      userDetail:{},
      userId:'',
      tabList:[
        {
          name:'学情分析',
          id:1,
          componentName:'StuStatistics'
        },
        {
          name:'操作记录',
          id:2,
          componentName:'RecordList'
        },
        {
          name:'积分记录',
          id:4,
          componentName:'Integral'
        },
      ],
      currentIndex:0,
      score:'',
    }
  },
  components:{
    RecordList,
    StuStatistics,
    TeacherAuth,
    Integral
  },
  created(){
    console.log("this.$route.params.id")
    console.log(this.$route)
    if (this.$route.params.id){
        this.userId = this.$route.params.id
    }

    this.getDetail();
  },
  methods:{
    getDetail(){
      let params = {
        with_score:1
      };
      if (this.userId){
        params.user_id = this.userId
      }

      this.api.user.userDetail(params).then((res)=>{
        this.userDetail = res.user;
        this.score = res.score;
        this.userDetail.created_at = util.timeFormatter(new Date(+res.user.created_at*1000), 'yyyy-MM-dd hh:mm');
        for(let name in this.userDetail.auth_roles){
          if(name == 'role_teacher' && this.$route.name == 'user/detail'){
            this.tabList.push({
              name:'师资认证',
              id:3,
              componentName:'TeacherAuth'
            })
          }
        }
      })
    },
    changeTab(data){
      this.currentIndex = data;
    },
    showDate(time)
    {
      return util.timeFormatter(new Date(+time*1000), 'yyyy-MM-dd hh:mm')
    }
  }
}
</script>

<style scoped lang="scss">
.user-detail{
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);

    .head-img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color:#FFFFFF;
      >img{
        width: 100%;
        height: 100%;
        border-radius: 80px;
      }
    }

    .project-detail-info .info-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .tab-cont{
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;
    //min-height: 180px;
    .tab-top{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      .tab-top-l{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border:1px solid #dcdee2;
        height: 100%;
        border-radius: 4px;
        >div{
          width: 150px;
          border-right:1px solid #dcdee2;
          cursor: pointer;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        >div:last-child{
          border-right:none;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }

    }

  }
}
</style>
