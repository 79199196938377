<template>
<div class="integral">
  <p class="integral-num">当前剩余积分：{{ score }}</p>
  <Table border :columns="columns" :loading="loading" :data="data"></Table>
  <div class="page">
    <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "integral.vue",
  data(){
    return {
      columns:[
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: '类型',
          key: 'from_type',
          render: (h, para) => {
            return h('div', {
            }, this.fromTypes[para.row.from_type]);
          },
        },
        {
          title: '名称',
          render: (h, para) => {
            return h('div', {}, para.row.item_info && para.row.item_info.name);
          },
        },
        {
          title: '收入/支出',
          key: '',
          render: (h, para) => {
            return h('div', {}, (para.row.type == '0' ? '+' : '-') + para.row.score );
          },
        },
        {
          title: '操作时间',
          render: (h, para) => {
            if (para.row.created_at) {
              return h('span', util.timeFormatter(new Date(+para.row.created_at*1000), 'yyyy-MM-dd hh:mm'))
            }
          },
        },
      ],
      data:[],
      loading:false,
      page:1,
      pageSize:10,
      total:0,
      userId:'',
      fromTypes:{},
    }
  },
  props:{
    score:{
      type:String,
      default:''
    }
  },
  created(){
    this.userId = this.$route.params.id || '';
    this.getList();
  },
  methods:{
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        site_id:'-1',
        user_id:this.userId
      };
      this.loading = true;
      this.api.score.recordList(params).then((res)=>{
        this.loading = false;
        this.data = res.list;
        this.total = Number(res.count);
        this.fromTypes = res.fromTypes;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
  }
}
</script>

<style scoped lang="scss">
.integral{
  margin: 20px 0;
  font-size: 14px;
  .integral-num{
    margin-bottom: 20px;
    width: 200px;
    height: 60px;
    background-color: #f8f8f9;
    text-align: center;
    line-height: 60px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
